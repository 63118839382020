/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Link as LinkComponent } from 'gatsby'
import { Themed, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

const getLinkStyle = (isFooter) => ({
  textDecoration: 'none !important',
  fontWeight: isFooter ? 'light' : 'body',
  fontSize: isFooter ? 2 : 3,
  color: isFooter ? 'charcoal' : 'summerSky',
  ':hover': {
    color: isFooter ? 'charcoal' : 'summerSky',
    opacity: 0.5,
    transition: 'color .15s ease-out',
  },
})

const Link = ({ href, text, isInternal = true, isFooter = false }) => {
  const { t } = useTranslation()
  const linkStyle = getLinkStyle(isFooter)
  return (
    <>
      {isInternal ? (
        <LinkComponent to={href} sx={linkStyle}>
          {t(text)}
        </LinkComponent>
      ) : (
        <Themed.a href={href} sx={linkStyle} target="_blank" rel="noreferrer">
          {t(text)}
        </Themed.a>
      )}
    </>
  )
}

export const LinkWrapper = ({ children, href }) => {
  const linkStyle = getLinkStyle(false)
  return (
    <LinkComponent to={href} sx={linkStyle}>
      {children}
    </LinkComponent>
  )
}

export default Link
